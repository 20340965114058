import { AlternateEmail, Email, LocalPhone, Person } from '@mui/icons-material';
import { Box, Typography, Container, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from 'gatsby-theme-material-ui';
import * as React from "react";

const Contact = () => (
  <Box sx={{ bgcolor: 'primary.main', color: '#fff'}} pt={5} pb={4}>
    <Container maxWidth="lg">
      <Typography variant="h3" component="h2" gutterBottom>
        Feel free to contact us!
      </Typography>
      <List>
        <ListItem disablePadding>
          <ListItemIcon>
            <Person color="white" />
          </ListItemIcon>
          <ListItemText primary={<React.Fragment>
              <Typography color={'#fff'} component="span" variant="h6">Dan Keller</Typography> </React.Fragment>} />
        </ListItem>
         <ListItem disablePadding>
          <ListItemIcon>
            <AlternateEmail color="white" />
          </ListItemIcon>
          <ListItemText primary={<React.Fragment>
              <Link href="mailto: sales@highvolt.com"><Typography color={'#fff'} component="span" variant="h6">sales@highvolt.com</Typography></Link> </React.Fragment>} />
        </ListItem>
        <ListItem disablePadding>
          <ListItemIcon>
            <LocalPhone color="white" />
          </ListItemIcon>
          <ListItemText primary={<React.Fragment>
              <Link href="tel: +49 351 8425 700"><Typography color={'#fff'} component="span" variant="h6">+49 351 8425 700</Typography></Link></React.Fragment>} />
        </ListItem>
        <ListItem alignItems="flex-start" disablePadding>
          <ListItemIcon>
            <Email color="white" />
          </ListItemIcon>
          <ListItemText primary={<React.Fragment>
              <Typography color={'#fff'} component="span" variant="h6">HIGHVOLT</Typography> </React.Fragment>} secondary={
            <React.Fragment>
              <Typography color={'#fff'} variant="h6" component="span" sx={{ display: "block" }}>Prüftechnik Dresden GmbH</Typography>
              <Typography color={'#fff'} variant="h6" component="span" sx={{ display: "block" }}>Marie-Curie-Strasse 10</Typography>
              <Typography color={'#fff'} variant="h6" component="span" sx={{ display: "block" }}>01139 Dresden</Typography>
              <Typography color={'#fff'} variant="h6" component="span">Germany</Typography>
            </React.Fragment>
          } />
        </ListItem>
      </List>
    </Container>
  </Box>
);

export default Contact;
