import * as React from "react";
import { Box, Typography, Paper, Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

const IncreaseHimon = () => (
  <Box pt={5} pb={4}>
    <Typography variant="h3" component="h2" gutterBottom mb={4}>
      Increase in availability for cable systems
    </Typography>
    <Grid container spacing={2} mb={3}>
      <Grid item xs={12} sm={8}>
        <StaticImage
          alt="graphic increase availability HiMON"
          src="../images/Increase-Availability.png"
          placeholder="blurred"
        />
      </Grid>
      <Grid item xs={12} sm={4} mt={3}>
        <Paper
          elevation={0}
          square
          sx={{
            bgcolor: "#ca2627",
            padding: 2,
            color: "white.main",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: 2,
          }}
        >
          <Typography variant="h5">
            Target: Reduce 30 % of repair time
          </Typography>
        </Paper>
        <Paper
          elevation={0}
          square
          sx={{
            bgcolor: "#1287a6",
            padding: 2,
            color: "white.main",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h5">
            Target: 70 % of faults are found in advance, so they can be repaired
            during planned shutdowns.
          </Typography>
        </Paper>
      </Grid>
    </Grid>
    <Typography variant="h4" gutterBottom align="center">
      HiMON<sup>®</sup> makes the availability of cable systems comparable to
      that of overhead lines.
    </Typography>
  </Box>
);

export default IncreaseHimon;
