import { Download } from '@mui/icons-material';
import { Box, Typography, List, ListItem, Avatar, ListItemText, ListItemAvatar } from '@mui/material';
import { Link } from 'gatsby-theme-material-ui';
import * as React from "react"

import PresentationFile from '../downloads/HiMON.pdf';
import BrochureFile from '../downloads/HIGHVOLT_HiMON_web.pdf'

const entries = [
  {
    title: 'Presentation',
    href: PresentationFile
  },
  {
    title: 'Brochure',
    href: BrochureFile
  }
]

const AdditionalRessources = () => (
  <Box pt={5} pb={4}>
    <Typography variant="h3" component="h2" gutterBottom mb={4}>
      Additional information
    </Typography>
    
    <List>
      {entries.map((entry, index) => (
        <ListItem key={index}>
          <ListItemAvatar>
            <Link href={entry.href} target="_blank"><Avatar>
              <Download />
            </Avatar></Link>
            
          </ListItemAvatar>
          <ListItemText primary={entry.title} />
        </ListItem>
      ))}
    </List>
  </Box>
);

export default AdditionalRessources;
