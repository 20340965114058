import * as React from "react"
import Container from '@mui/material/Container'
import { Box, Typography, AppBar, Toolbar, Fab, Dialog, DialogContent, useTheme, useMediaQuery } from '@mui/material'
import MailIcon from '@mui/icons-material/Mail'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Link } from 'gatsby-theme-material-ui'
import LogoHighvolt from '../components/LogoHighvolt'
import LogoHimon from '../components/LogoHimon'
import Video from '../components/Video'
import CompareTransmission from '../components/CompareTransmission'
import IncreaseAvailability from '../components/IncreaseAvailability'
import IncreaseHimon from '../components/IncreaseHimon'
import ProductServiceOverview from '../components/ProductServiceOverview'
import AddedAvailability from '../components/AddedAvailability'
import SystemIntegration from '../components/SystemIntegration'
import Benefits from '../components/Benefits'
import Contact from '../components/Contact'
import AdditionalRessources from '../components/AdditionalRessources'
import Footer from '../components/Footer'
import CalculationDowntimeCost from '../components/CalculationDowntimeCost'
import Methods from '../components/Methods'
import AdditionalPapers from '../components/AdditionalPapers.js'
import ContactForm from '../components/ContactForm'

export const Head = () => (
  <>
    <title>HIGHVOLT HiMON® – Increase availability of cable systems</title>
    <meta name="viewport" content="initial-scale=1, width=device-width" />
    <meta name="description" content="HiMON® – Modular measuring and condition assessment system to increase the availability of HVAC and HVDC cables by combining functions across the lifecycle" />
  </>
)

const IndexPage = () => {
  const [openForm, setOpenForm] = React.useState(false);
  const handleOpen = () => setOpenForm(true);
  const handleClose = () => setOpenForm(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <AppBar component="nav" sx={{ bgcolor: 'white.main' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
           <IconButton component={Link} to="/">
            <LogoHimon />
          </IconButton>
          <IconButton component={Link} to="https://highvolt.com" target="_blank">
            <LogoHighvolt />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Video />
      <Container maxWidth="lg">
        <Typography variant="h4" component="h3" gutterBottom pt={6} pb={5} textAlign="center">
         HiMON<sup>®</sup> – Modular measuring and condition assessment system for fast localization of cable breakdowns and evaluation of partial discharges
        </Typography>
        <CompareTransmission />
        <IncreaseAvailability />
        <IncreaseHimon />
        <ProductServiceOverview />
        <Methods />
        <AddedAvailability />
        <SystemIntegration />
        <CalculationDowntimeCost />
        <Benefits />
        <AdditionalRessources />
        <AdditionalPapers />
      </Container>
      <Contact />
      <Footer />
      <Dialog
        maxWidth={'lg'}
        fullWidth={true}
        fullScreen={fullScreen}
        open={openForm}
        onClose={handleClose}
        PaperProps={{
          sx: {minHeight: '65vh'}
        }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography variant='h4' component='div' py={2} sx={{flexGrow: 1}}>Get your quote!</Typography>
            <IconButton edge="end" color='inherit' onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{display: 'flex', flexDirection: 'column'}}>
          <ContactForm />
        </DialogContent>
      </Dialog>
      <Fab style={{ position: 'fixed', bottom: '1.5rem', right: '1.5rem' }} color="secondary" onClick={handleOpen} aria-label="contact">
        <MailIcon />
      </Fab>
    </Box>
  )
}

export default IndexPage
