import { Box, Typography } from "@mui/material";
import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

function ProductServiceOverview() {
  return (
    <Box pt={5} pb={4}>
      <Typography variant="h3" component="h2" gutterBottom mb={5}>
        Product and service overview
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box sx={{ width: {xs: '100%'}}}>
          <StaticImage
            alt="Product and service overview"
            src="../images/Product-Overview.png"
            placeholder="blurred"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ProductServiceOverview;
