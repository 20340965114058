import { Box, Typography, Paper, Grid } from '@mui/material';
import * as React from "react"
import { StaticImage } from "gatsby-plugin-image";

const entries = [
  {
    title: 'Manufacturing',
    content: ['Fast localization of cable breakdowns', 'Quality assurance (PD) for joints/cables']
  },
  {
    title: 'Installation',
    content: ['Theft monitoring for the cable system', 'Quality assurance (TF) during on site joint installation']
  },
  {
    title: 'Commissioning',
    content: ['Fast localization of cable breakdowns', 'Quality assurance (PD) for joints/cables']
  },
   {
    title: 'Operation',
    content: ['Fast localization of cable breakdowns', 'Small scale event evaluation', 'Risk analysis']
  }
]

const AddedAvailability = () => (
  <Box pt={5} pb={4}>
    <Typography variant="h3" component="h2" gutterBottom mb={4}>
      Added availability of transmission systems functions
    </Typography>

    <StaticImage
      alt="Added availability graphic"
      src="../images/Added-Availability.png"
      placeholder="blurred"
    />

    <Grid container spacing={3} justifyContent="center" mt={3}>
      {entries.map((entry, index) => (
        <Grid item key={index} xs={6} sm={5} md={3}>
          <Paper elevation={0} sx={{
            bgcolor: 'hvgray.light', 
            padding: 2, 
            color: 'hvgray.dark', 
            textAlign: 'left', 
            aspectRatio: {lg: '1 / 1'}, 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'start',
            height: "100%"
          }}>
          <Typography variant="h4" gutterBottom>
            {entry.title}
          </Typography>
          <ul style={{ listStyleType: 'square' }}>
            {entry.content.map((content, index) => (
            <li key={index}>
              <Typography variant="h6" component="p" gutterBottom key={index}>
              {content}
            </Typography>
            </li>
          ))}
          </ul>
          
        </Paper>
      </Grid>
      ))}
    </Grid>
    
    <Typography variant="h4" align='center' mt={3}>
      HiMON<sup>®</sup> increases the availability through combining functions in every phase of a cable lifespan
    </Typography>
  </Box>
);

export default AddedAvailability;
