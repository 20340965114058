import * as React from "react";

const Video = () => (
  <div style={{ position: 'relative', paddingTop: '56.25%' }}>
    <iframe 
      src="https://iframe.mediadelivery.net/embed/56729/449c50be-08cb-439d-ba6c-773e669a0f09?autoplay=false&preload=false" 
      loading="lazy"
      style={{ border: 'none', position: 'absolute', top: 0, height: '100%', width: '100%' }} allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;" 
      allowFullScreen={true}>
    </iframe>
  </div>
)

export default Video