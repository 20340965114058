import { Box, Typography, Paper, Grid } from '@mui/material';
import * as React from "react"

const entries = [
  'Fast localization of cable breakdowns',
  'Detection of oncoming faults',
  'Evaluation of aging'
]

const IncreaseAvailability = () => (
  <Box pt={5} pb={4}>
    <Typography variant="h3" component="h2" gutterBottom mb={3}>
      How to increase availability of cable transmission lines?
    </Typography>
    <Typography variant="h5" component="h3" gutterBottom mb={3}>
      Condition assessment during factory testing, commissioning and operation
    </Typography>
    <Grid container spacing={3} justifyContent="center">
      {entries.map((entry, index) => (
        <Grid item key={index} xs={6} sm={4} lg={3}>
          <Paper elevation={0} sx={{
            bgcolor: 'hvgray.light', 
            padding: 2, 
            color: 'hvgray.dark', 
            textAlign: 'center', 
            aspectRatio: '1 / 1', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center'
          }}>
          <Typography variant="h4">
            {entry}
          </Typography>
        </Paper>
      </Grid>
      ))}
    </Grid>
  </Box>
);

export default IncreaseAvailability;
