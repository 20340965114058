import { Box, Typography, Grid, Tab, Stack, styled } from "@mui/material";
import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

function Methods() {
  const tabRef = React.useRef(null);
  const [tabHeight, setTabHeight] = React.useState(0);
  const [value, setValue] = React.useState("1");

  React.useEffect(() => {
    setTabHeight(tabRef.current.clientHeight);
  }, [tabRef]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const CustomTab = styled(Tab)(({ theme }) => ({
    textTransform: "unset",
    fontFamily: "Oswald",
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    "&.Mui-selected": {
      color: "white",
      backgroundColor: theme.palette.primary.main,
    },
  }));

  return (
    <Box pt={5} pb={4}>
      <Typography variant="h3" component="h2" gutterBottom mb={3}>
        Methods
      </Typography>
      <TabContext value={value}>
        <Box sx={{ width: "100%" }}>
          <TabList
            onChange={handleChange}
            sx={{ mt: 3, width: "100%" }}
            variant="fullWidth"
          >
            <CustomTab
              label="Evaluation of small scale events"
              value="1"
              wrapped
            />
            <CustomTab
              label="Fast localization of cable breakdowns"
              value="2"
              wrapped
            />
            <CustomTab label="Expert system" value="3" wrapped />
          </TabList>
        </Box>
        <TabPanel
          value="1"
          ref={tabRef}
          sx={{
            minHeight: tabHeight,
            bgcolor: "primary.main",
            color: "white.main",
          }}
        >
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Stack
                p={2}
                direction="column"
                spacing={4}
                sx={{ width: "100%", bgcolor: "white.main" }}
              >
                <StaticImage
                  alt="TruePD 1 graphic"
                  src="../images/TruePD1.png"
                  placeholder="blurred"
                />

                <StaticImage
                  alt="TruePD 2 graphic"
                  src="../images/TruePD2.png"
                  placeholder="blurred"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom>
                RT-TF Analysis and synthesis of all cable sections
              </Typography>
              <Typography mb={2}>
                Periodic modelling of a cable section between two sensor units.
                Injection of small impulses into the cable in order to determine
                the transfer function of the cable section
              </Typography>
              <Typography variant="h4" gutterBottom>
                TruePD Measurement and filtering of real signals
              </Typography>
              <Typography>
                Localization and determination of detected partial discharges at
                the location of the fault under consideration of the actual
                cable transfer function. Classification and characterization of
                the events with AI algorithms.
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel
          value="2"
          sx={{
            minHeight: tabHeight,
            bgcolor: "primary.main",
            color: "white.main",
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                p={2}
                alignItems="center"
                flexDirection="column"
                sx={{ width: "100%", bgcolor: "white.main" }}
              >
                <Box sx={{ width: "100%" }}>
                  <StaticImage
                    alt="RT-TDR graphic"
                    src="../images/RT-TDR.png"
                    placeholder="blurred"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom>
                Cable breakdown
              </Typography>
              <Typography mb={2}>
                A break down within the cable insulation causes a high power
                transient impulse that leads to travelling waves moving in both
                directions of the fault location
              </Typography>
              <Typography variant="h4" gutterBottom>
                Fault localization
              </Typography>
              <Typography>
                Determining the location of the fault under consideration of the
                measured transit time of the travelling waves
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel
          value="3"
          sx={{
            minHeight: tabHeight,
            bgcolor: "primary.main",
            color: "white.main",
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Stack
                p={7}
                direction="column"
                alignItems="center"
                spacing={4}
                sx={{ width: "100%", bgcolor: "white.main" }}
              >
                <Box sx={{ width: "85%" }}>
                  <StaticImage
                    alt="Expert system graphic 1"
                    src="../images/expert1.png"
                    placeholder="blurred"
                  />
                </Box>
                <StaticImage
                  alt="Expert system graphic 2"
                  src="../images/expert2.png"
                  placeholder="blurred"
                />
                <StaticImage
                  alt="Expert system graphic 3"
                  src="../images/expert3.png"
                  placeholder="blurred"
                />
                <StaticImage
                  alt="Expert system graphic 4"
                  src="../images/expert4.png"
                  placeholder="blurred"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom>
                Expert System
              </Typography>
              <Typography>
                The HiMON<sup>®</sup> Intelligence Cluster is a cloud-based
                solution for evaluating the transmitted measured values. The
                extensive knowledge of our experts is used to continuously
                improve stochastic algorithms and artificial intelligence
                methods.
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default Methods;
