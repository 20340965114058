import { Box, Typography } from '@mui/material';
import * as React from "react"
import { StaticImage } from "gatsby-plugin-image";

const SystemIntegration = () => (
  <Box pt={5} pb={4}>
    <Typography variant="h3" component="h2" gutterBottom mb={5}>
      System integration
    </Typography>

    <Box display="flex" flexDirection="column" alignItems="center">
      <Box sx={{ width: {xs: '100%', md: '90%'}}}>
        <StaticImage
          alt="System integration graphic"
          src="../images/System-Integration.png"
          placeholder="blurred"
        />
      </Box>
    </Box>
    
    <Typography variant="h4" align='center' sx={{ mt: 3 }}>
      Installation of measuring points every 12 km is worldwide unique
    </Typography>
  </Box>
);

export default SystemIntegration;
