import { Box, Typography, Container, Stack } from "@mui/material";
import * as React from "react";
import { Link } from 'gatsby-theme-material-ui'

const Footer = () => (
  <Box sx={{ bgcolor: 'primary.dark'}}>
    <Container maxWidth="lg">
      <Stack direction="row" spacing={3} justifyContent="space-between" pt={4} pb={3}>
        <Typography variant="body2" color={'#fff'}>
          HiMON<sup>®</sup> is a product by <Link href="https://www.highvolt.com" target="_blank" color={'#fff'}>HIGHVOLT</Link>
        </Typography>
        <Box>
          <Link href="https://www.highvolt.de/en/desktopdefault.aspx/tabid-1621" target="_blank" color={'#fff'} mr={2}>Imprint</Link>
          <Link href="https://www.highvolt.de/en/desktopdefault.aspx/tabid-2295/3017_read-8452/" target="_blank" color={'#fff'}>Privacy</Link>
        </Box>
      </Stack>
    </Container>
  </Box>
);

export default Footer;
