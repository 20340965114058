import { Box, Typography, Paper, Grid, Icon } from '@mui/material';
import * as React from "react"
import HandshakeSVG from '../images/handshake.svg';

const entries = [
  {
    title: 'HIGHVOLT is neutral',
    icon: 'accessibility_new'
  },
  {
    title: 'Reduction of downtime costs',
    icon: 'payments'
  },
  {
    title: 'Increase of availability',
    icon: 'query_stats'
  },
  {
    title: 'Suitable for new and existing cable',
    icon: 'autorenew'
  },
  {
    title: 'HIGHVOLT is a reliable project partner',
    icon: 'handshake'
  }
]

const Benefits = () => (
  <Box pt={5} pb={4}>
    <Typography variant="h3" component="h2" gutterBottom mb={5}>
      Benefits
    </Typography>

    <Grid container spacing={4} justifyContent="center">
      {entries.map((entry, index) => (
        <Grid item key={index} xs={5} md={3}>
          <Paper
            elevation={0}
            sx={{
              bgcolor: "primary.main",
              padding: 2,
              color: "white.main",
              textAlign: "center",
              aspectRatio: { sm: "1 / 1" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {entry.icon === "handshake" ? (
              <Icon sx={{ mb: 2, fontSize: "48px" }}>
                <img alt="Handshake icon" src={HandshakeSVG} />
              </Icon>
            ) : (
              <Icon sx={{ mb: 2, fontSize: "40px" }}>{entry.icon}</Icon>
            )}
            <Typography variant="h4" gutterBottom>
              {entry.title}
            </Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  </Box>
);

export default Benefits;
