import { Box, Typography, Paper, Grid } from '@mui/material';
import * as React from "react"

const entries = [
  {
    type: 'Underground cable',
    availability: '95 %'
  },
  {
    type: 'Subsea cable',
    availability: '92-97 %'
  },
  {
    type: 'Overhead line',
    availability: '> 99 %'
  }
]

const CompareTransmission = () => (
  <Box pt={5} pb={4}>
    <Typography variant="h3" component="h2" gutterBottom mb={3}>
      Availability comparision of transmission lines
    </Typography>
    <Typography variant="h5" component="h3" mb={4}>
      (Approximate availability depends on cable length)
    </Typography>
    <Grid container spacing={3} justifyContent="center">
      {entries.map((entry, index) => (
        <Grid item key={index} xs={6} sm={4} lg={3}>
          <Paper elevation={0} sx={{
            bgcolor: 'primary.main', 
            padding: 2, 
            color: 'white.main', 
            textAlign: 'center', 
            aspectRatio: '1 / 1', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center'
          }}>
          <Typography variant="h4">
            {entry.type}
          </Typography>
          <Typography variant="h4">
            {entry.availability}
          </Typography>
        </Paper>
      </Grid>
      ))}
    </Grid>
  </Box>
);

export default CompareTransmission;
